<template>
  <page container-type="centered">
    <page-row>
      <page-column>
        <echo-form
          :title="$t('oudemaas.title')"
          :explanation="$t('oudemaas.explanation')"
          :model="model"
          :form="form"
          submit-action="REQUEST_OUDE_MAAS"
          data-test="oude-maas"
        >
          <field-set :legend="$t('oudemaas.legend.visit')" data-test="visit">
            <input-ucrn
              :form="form"
              :model="model"
              model-key="ucrn"
              :validators="{
                required: { messageTranslationKey: 'form.ucrn.required' },
                myUcrn: true,
              }"
              :label="$t('oudemaas.ucrn')"
              data-test="ucrn"
            />

            <input-select
              :form="form"
              :model="model"
              model-key="movementCommId"
              :validators="{ required: true }"
              :label="$t('oudemaas.movement.label')"
              :placeholder="$t('oudemaas.movement.placeholder')"
              :options="movementOptions"
              data-test="movement"
            />

            <ship-summary v-if="vesselForSummary" :vessel="vesselForSummary" />

            <b-alert class="info-message" variant="info" show>
              <strong>{{ $t('generic.message.caution') }}</strong>
              {{ $t('oudemaas.message') }}
            </b-alert>

            <input-number
              key="beam"
              :form="form"
              :model="model"
              model-key="beam"
              :label="$t('oudemaas.beam.label')"
              :placeholder="$t('oudemaas.beam.placeholder')"
              append="m"
              data-test="beam"
            />

            <input-number
              key="draught"
              :form="form"
              :model="model"
              model-key="draught"
              :label="$t('oudemaas.draught.label')"
              :placeholder="$t('oudemaas.draught.placeholder')"
              :validators="{ required: true }"
              append="m"
              data-test="draught"
            />

            <input-number
              key="airdraft"
              :form="form"
              :model="model"
              model-key="airdraft"
              :label="$t('oudemaas.airdraft.label')"
              :placeholder="$t('oudemaas.airdraft.placeholder')"
              :validators="{ required: true }"
              append="m"
              data-test="airdraft"
            />
          </field-set>
        </echo-form>
      </page-column>
    </page-row>
  </page>
</template>

<script>
import PageRow from 'poronline-shared-vue-components/components/page/row';
import PageColumn from 'poronline-shared-vue-components/components/page/column';
import FieldSet from 'poronline-shared-vue-components/components/form/field-set';
import InputUcrn from 'poronline-shared-vue-components/components/form/input-ucrn';
import InputNumber from 'poronline-shared-vue-components/components/form/input-number';
import InputSelect from 'poronline-shared-vue-components/components/form/input-select';
import Page from '../../components/page/page';
import EchoForm from '../../components/form/echo-form';
import ShipSummary from '../../components/form/ship-summary';
import withVisit from '../../components/form/with-visit';
import { BAlert } from 'bootstrap-vue';

export default {
  components: {
    BAlert,
    Page,
    PageRow,
    PageColumn,
    EchoForm,
    FieldSet,
    InputUcrn,
    InputNumber,
    InputSelect,
    ShipSummary,
  },
  mixins: [withVisit],
  props: {
    copy: Object,
  },
  data() {
    const model = {};

    if (this.copy) {
      Object.assign(model, this.copy);
    }

    if (this.$route.query.ucrn) {
      model.ucrn = this.$route.query.ucrn;
    }
    return { model, form: {} };
  },
  computed: {
    vesselForSummary() {
      return this.model.ucrn && this.form?.ucrn?.$valid
        ? {
            shipName: this.selectedVessel?.shipName,
            shipNumber: this.selectedVessel?.shipNumber,
            length: this.selectedVessel?.length,
            beam: this.selectedVessel?.beam,
            grossTonnage: this.selectedVessel?.grossTonnage ?? null,
            nettTonnage: this.selectedVessel?.nettTonnage ?? null,
            deadWeight: this.selectedVessel?.deadWeight ?? null,
          }
        : undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.info-message {
  border-width: 1px;
  border-radius: 2px;
}
</style>
